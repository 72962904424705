import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { UserSignInDto, UserSignUpDto } from '../../api/api';
import { AuthFormValues } from '../../types/authFormValuesType';
import { AuthModalType } from '../../types/modalType';
import { AuthStrategy, LoginStrategy, RecoveryStrategy, SignUpStrategy } from './authStrategies';

const strategyMap: Record<AuthModalType, AuthStrategy> = {
  [AuthModalType.LOGIN]: new LoginStrategy(),
  [AuthModalType.SIGNUP]: new SignUpStrategy(),
  [AuthModalType.RECOVERY]: new RecoveryStrategy(),
};

interface AuthContentProps {
  actionType: AuthModalType;
  onSubmit: (formValues: UserSignInDto | UserSignUpDto) => void;
  onChangeType: (newType: AuthModalType) => void;
  getAccessCode: () => string;
  isLoading: boolean;
  error: string | null;
}

const AuthContent: React.FC<AuthContentProps> = ({
  actionType,
  onSubmit,
  onChangeType,
  getAccessCode,
  isLoading,
  error,
}) => {
  const [formValues, setFormValues] = useState(strategyMap[actionType].getInitialValues());
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    resetValues();
    resetErrors();
  }, [actionType]);

  const resetValues = () => {
    setFormValues(strategyMap[actionType].getInitialValues());
  };

  const resetErrors = () => {
    setErrors({});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const fieldName = name as keyof AuthFormValues;
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    const error = strategyMap[actionType].getFieldError(fieldName, { ...formValues, [fieldName]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };

  const validate = (): boolean => {
    const newErrors = strategyMap[actionType].validate(formValues);
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;
    const preparedData = strategyMap[actionType].prepareData(formValues);
    onSubmit(preparedData);
  };

  const handleGetAccessCode = () => {
    const accessCodeUrl = getAccessCode();
    window.open(accessCodeUrl, '_blank');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      {actionType === AuthModalType.LOGIN && (
        <>
          <TextField
            label="Email"
            name="email"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your email"
            sx={{ mb: 2 }}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your password"
            sx={{ mb: 2 }}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />
          <Typography
            component="span"
            onClick={() => onChangeType(AuthModalType.RECOVERY)}
            sx={{ cursor: 'pointer', color: 'blue', mb: 2 }}>
            Account Recovery
          </Typography>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" type="submit" sx={{ flexGrow: 1 }} disabled={isLoading}>
              Log in
            </Button>
          </Box>
          <Typography sx={{ mt: 2 }}>
            Don't have an account?{' '}
            <Typography
              component="span"
              onClick={() => onChangeType(AuthModalType.SIGNUP)}
              sx={{ cursor: 'pointer', color: 'blue' }}>
              Signup
            </Typography>
          </Typography>
        </>
      )}
      {actionType === AuthModalType.SIGNUP && (
        <>
          <TextField
            label="Access code"
            name="accessCode"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your access code"
            sx={{ mb: 2 }}
            error={Boolean(errors.accessCode)}
            helperText={errors.accessCode}
          />
          <Typography
            component="span"
            onClick={handleGetAccessCode}
            sx={{ cursor: 'pointer', color: 'blue', display: 'inline-block', mb: 2 }}>
            Get an Access Code
          </Typography>
          <TextField
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Create a password"
            sx={{ mb: 2 }}
            error={Boolean(errors.password)}
            helperText={errors.password || 'Must be at least 8 characters.'}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Confirm password"
            sx={{ mb: 2 }}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" type="submit" sx={{ flexGrow: 1 }} disabled={isLoading}>
              Signup
            </Button>
          </Box>
          <Typography
            component="span"
            onClick={() => onChangeType(AuthModalType.LOGIN)}
            sx={{ cursor: 'pointer', color: 'blue', display: 'inline-block', mt: 2 }}>
            Return to login
          </Typography>
        </>
      )}
      {actionType === AuthModalType.RECOVERY && (
        <>
          <TextField
            label="Recovery code"
            name="accessCode"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your recovery code"
            sx={{ mb: 2 }}
            error={Boolean(errors.accessCode)}
            helperText={errors.accessCode}
          />
          <TextField
            label="New Password"
            name="password"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Enter new password"
            sx={{ mb: 2 }}
            error={Boolean(errors.password)}
            helperText={errors.password || 'Must be at least 8 characters.'}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Confirm password"
            sx={{ mb: 2 }}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => onChangeType(AuthModalType.LOGIN)}
              sx={{ flexGrow: 1 }}
              disabled={isLoading}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" sx={{ flexGrow: 1 }} disabled={isLoading}>
              Confirm
            </Button>
          </Box>
        </>
      )}
      <Box>
        <Typography sx={{ color: 'red', mt: 2 }}>{error}</Typography>
      </Box>
    </Box>
  );
};

export default AuthContent;
