import useSWR from 'swr';

import { TokenInfoDto } from '../api/api';
import { getTokenInfo } from '../utils/api';

interface UseTokenInfoResult {
  tokenInfo: TokenInfoDto | undefined;
  isLoading: boolean;
  error: string | null;
}

const fetchTokenInfo = async (networkId: string, address: string): Promise<TokenInfoDto> => {
  try {
    const result = await getTokenInfo(networkId, address);
    if (!result || !result.data) throw new Error('Failed to fetch token info');

    return result.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message || 'Failed to fetch token info');
    } else {
      throw new Error('Failed to fetch token info');
    }
  }
};

export const useTokenInfo = (networkId: string, address: string): UseTokenInfoResult => {
  const { data, error, isLoading } = useSWR<TokenInfoDto>(
    networkId && address ? [`tokenInfo`, networkId, address] : null,
    () => fetchTokenInfo(networkId, address)
  );

  return {
    tokenInfo: data,
    isLoading,
    error: error ? error.message : null,
  };
};
