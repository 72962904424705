import { Box, Button, Checkbox, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import { CustomFieldLabelDto, TokenListDto } from '../api/api';
import { usePartnersList } from '../hooks/usePartnersList';
import { DialogAction } from '../types/DialogType';
import ConfirmDialog from './ConfirmDialog';

interface CustomField {
  customFieldId?: number;
  name: string;
  customFieldType: CustomFieldLabelDto.CustomFieldTypeEnum;
  defaultValue: string;
  isArchived: boolean;
}

interface DetailsBlockProps {
  tokenList: TokenListDto | undefined;
  onClose: () => void;
}

const DetailsBlock: React.FC<DetailsBlockProps> = ({ tokenList, onClose }) => {
  const { updateList, updateCustomFields, createNewCustomFields } = usePartnersList();
  const [formState, setFormState] = useState({
    name: '',
    note: '',
    customFields: [] as CustomField[],
  });
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number | null>(null);
  const [dialogAction, setDialogAction] = useState<DialogAction | null>(null);

  useEffect(() => {
    if (tokenList) {
      const initialCustomFields: CustomField[] = tokenList.customFieldsLabels.map((field) => ({
        customFieldId: field.customFieldId,
        name: field.name,
        customFieldType: field.customFieldType,
        defaultValue: field.defaultValue,
        isArchived: field.isArchived,
      }));

      setFormState({
        name: tokenList.name,
        note: tokenList.note || '',
        customFields: initialCustomFields,
      });
    }
  }, [tokenList]);

  const handleAddField = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      customFields: [
        ...prevState.customFields,
        {
          name: '',
          customFieldType: CustomFieldLabelDto.CustomFieldTypeEnum.STRING,
          defaultValue: '',
          isArchived: false,
        },
      ],
    }));
  }, []);

  const handleChange = useCallback(
    (index: number, key: keyof CustomField, value: string | CustomFieldLabelDto.CustomFieldTypeEnum) => {
      setFormState((prevState) => ({
        ...prevState,
        customFields: prevState.customFields.map((field, i) => (i === index ? { ...field, [key]: value } : field)),
      }));
    },
    []
  );

  const handleSubmit = async () => {
    if (!tokenList) return;

    setLoading(true);

    try {
      const actions = [
        {
          condition: formState.name !== tokenList.name || formState.note !== tokenList.note,
          action: () =>
            updateList({
              newName: formState.name,
              newNote: formState.note,
              isArchived: false,
            }),
        },
        {
          condition: formState.customFields.some((field) => field.customFieldId),
          action: () =>
            updateCustomFields({
              customFields: formState.customFields
                .filter((field) => field.customFieldId)
                .map((field) => ({
                  customFieldId: field.customFieldId!,
                  newName: field.name,
                  newDefaultValue: field.defaultValue,
                  isArchived: field.isArchived,
                })),
            }),
        },
        {
          condition: formState.customFields.some((field) => !field.customFieldId),
          action: () =>
            createNewCustomFields({
              customFields: formState.customFields
                .filter((field) => !field.customFieldId)
                .map((field) => ({
                  name: field.name,
                  customFieldType: field.customFieldType,
                  defaultValue: field.defaultValue,
                })),
            }),
        },
      ];

      const promises = actions.filter((item) => item.condition).map((item) => item.action());
      await Promise.all(promises);
      onClose();
    } catch (error) {
      console.error('Submission failed', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedFieldIndex(null);
    setDialogAction(null);
  };

  const handleDialogConfirm = async () => {
    if (selectedFieldIndex !== null && dialogAction) {
      setLoading(true);

      const updatedFields = formState.customFields.map((field, i) =>
        i === selectedFieldIndex ? { ...field, isArchived: dialogAction === DialogAction.REMOVE } : field
      );

      setFormState((prevState) => ({
        ...prevState,
        customFields: updatedFields,
      }));

      try {
        const customFieldsToUpdate = updatedFields.filter((field) => field.customFieldId);

        if (customFieldsToUpdate.length > 0) {
          await updateCustomFields({
            customFields: customFieldsToUpdate.map((field) => ({
              customFieldId: field.customFieldId!,
              newName: field.name,
              newDefaultValue: field.defaultValue,
              isArchived: field.isArchived,
            })),
          });
        }
      } catch {
        alert('Failed to update custom fields. Please try again.');
      } finally {
        setLoading(false);
      }

      handleDialogClose();
    }
  };

  const fieldTypeMap: Record<
    CustomFieldLabelDto.CustomFieldTypeEnum,
    (field: CustomField, index: number) => JSX.Element
  > = {
    [CustomFieldLabelDto.CustomFieldTypeEnum.STRING]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
        disabled={field.isArchived}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.BOOLEAN]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        select
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
        disabled={field.isArchived}>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </TextField>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.DATE]: (field, index) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={field.defaultValue ? dayjs(field.defaultValue) : null}
          onChange={(date: Dayjs | null) => handleChange(index, 'defaultValue', date ? date.format('YYYY-MM-DD') : '')}
          disabled={field.isArchived}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
            },
          }}
        />
      </LocalizationProvider>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.NUMBER]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        type="number"
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
        disabled={field.isArchived}
      />
    ),
  };

  const renderFieldByType = useCallback(
    (field: CustomField, index: number) => {
      return fieldTypeMap[field.customFieldType]
        ? fieldTypeMap[field.customFieldType](field, index)
        : fieldTypeMap[CustomFieldLabelDto.CustomFieldTypeEnum.STRING](field, index);
    },
    [fieldTypeMap, handleChange]
  );

  const renderActionCheckbox = (field: CustomField, index: number) => (
    <Checkbox
      checked={!field.isArchived}
      onChange={() => {
        setSelectedFieldIndex(index);
        setDialogOpen(true);
        setDialogAction(field.isArchived ? DialogAction.ADD : DialogAction.REMOVE);
      }}
    />
  );

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <Typography variant="subtitle1" color="textSecondary">
            Name
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={formState.name}
            onChange={(e) => setFormState((prevState) => ({ ...prevState, name: e.target.value }))}
          />
        </Box>
        <Box>
          <Typography variant="subtitle1" color="textSecondary">
            Description
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            multiline
            minRows={1}
            maxRows={5}
            value={formState.note}
            onChange={(e) => setFormState((prevState) => ({ ...prevState, note: e.target.value }))}
          />
        </Box>

        <Divider sx={{ width: '100%' }} />

        <Typography variant="subtitle1" color="textSecondary">
          Custom Params
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr auto" gap={2} alignItems="center">
          <Typography variant="subtitle2">Label</Typography>
          <Typography variant="subtitle2">Type</Typography>
          <Typography variant="subtitle2">Default Value</Typography>
          <Typography variant="subtitle2">Active</Typography>

          {formState.customFields.map((field, index) => (
            <React.Fragment key={field.customFieldId || index}>
              {field.customFieldId ? (
                <Typography>{field.name}</Typography>
              ) : (
                <TextField
                  variant="outlined"
                  size="small"
                  value={field.name}
                  onChange={(e) => handleChange(index, 'name', e.target.value)}
                  disabled={field.isArchived}
                />
              )}

              {field.customFieldId ? (
                <Typography>{field.customFieldType}</Typography>
              ) : (
                <TextField
                  variant="outlined"
                  size="small"
                  select
                  value={field.customFieldType}
                  onChange={(e) =>
                    handleChange(
                      index,
                      'customFieldType',
                      e.target.value as unknown as CustomFieldLabelDto.CustomFieldTypeEnum
                    )
                  }
                  disabled={field.isArchived}>
                  {Object.values(CustomFieldLabelDto.CustomFieldTypeEnum).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {renderFieldByType(field, index)}
              {renderActionCheckbox(field, index)}
            </React.Fragment>
          ))}
        </Box>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAddField}
            sx={{
              padding: '0.25rem 8rem',
              width: '100%',
              height: '2rem',
            }}>
            +
          </Button>
        </Box>
      </Box>

      <Box display="flex" justifyContent="end" gap={2} mt={3}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? 'Submitting...' : 'Confirm'}
        </Button>
      </Box>

      <ConfirmDialog
        open={dialogOpen}
        action={dialogAction}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </Box>
  );
};

export default DetailsBlock;
