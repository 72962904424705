import { CardMedia } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { generateRadialGradientFromText } from '../utils/helpers';

const ListCard: React.FC<{ title: string; description?: string }> = ({ title, description }) => {
  const gradientStyle = generateRadialGradientFromText(title);

  return (
    <Card
      sx={{
        borderRadius: '0.75rem',
        width: '100%',
        maxWidth: '20rem',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
      }}>
      <CardActionArea sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardMedia
          component="div"
          sx={{
            width: 'calc(100% - 1.4rem)',
            height: '8rem',
            backgroundImage: gradientStyle,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '0.5rem',
            margin: '0.7rem 0.7rem 0 0.7rem',
          }}
        />
        <CardContent sx={{ padding: '0', margin: '0.7rem', flex: 1, width: 'calc(100% - 1.4rem)' }}>
          <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
            {title}
          </Typography>
          {description && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {description}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ListCard;
