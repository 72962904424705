import DashboardIcon from '@mui/icons-material/Dashboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, CssBaseline, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { BASE_PATH } from '../constants/routes';
//import { useAuthActions } from '../hooks/useAuthActions';
import { useUserInfo } from '../hooks/useUserInfo';
import UserProfile from './UserProfile';

const drawerWidth = 240;

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { decodedJwt, firstProjectLink } = useUserInfo();
  //const { logout } = useAuthActions();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
          },
        }}
        variant="permanent"
        open>
        <Box
          sx={{
            padding: '2rem',
            textAlign: 'left',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            height: '3.125rem',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            marginTop: '1rem',
          }}>
          <Typography variant="h6" noWrap>
            LOGO
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}>
          <List
            sx={{
              padding: '1rem 0',
              gap: '0.625rem',
            }}>
            <NavLink to={firstProjectLink} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton
                sx={{
                  paddingLeft: '2rem',
                  height: '3.75rem',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: '2.5rem',
                  }}>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Project" />
              </ListItemButton>
            </NavLink>

            <NavLink to={BASE_PATH.ANALYTICS} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton
                sx={{
                  paddingLeft: '2rem',
                  height: '3.75rem',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: '2.5rem',
                  }}>
                  <PieChartIcon />
                </ListItemIcon>
                <ListItemText primary="Analytics" />
              </ListItemButton>
            </NavLink>

            <NavLink to={BASE_PATH.SETTINGS} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton
                sx={{
                  paddingLeft: '2rem',
                  height: '3.75rem',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: '2.5rem',
                  }}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </NavLink>
          </List>
        </Box>
        <Box>
          <ListItemButton
            //onClick={logout}
            sx={{
              paddingLeft: '2rem',
              marginBottom: '1.75rem',
            }}>
            <UserProfile name={decodedJwt.name} email={decodedJwt.email} />
          </ListItemButton>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: '2.5rem',
          height: '100vh',
          overflow: 'auto',
        }}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
