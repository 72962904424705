import { UserSignInDto, UserSignUpDto } from '../../api/api';
import { AuthFormValues } from '../../types/authFormValuesType';

export interface AuthStrategy {
  getInitialValues(): AuthFormValues;
  validate(values: AuthFormValues): Record<string, string>;
  getFieldError(field: keyof AuthFormValues, values: AuthFormValues): string;
  prepareData(values: AuthFormValues): UserSignInDto | UserSignUpDto;
}

export abstract class BaseAuthStrategy implements AuthStrategy {
  abstract getInitialValues(): AuthFormValues;
  abstract prepareData(values: AuthFormValues): UserSignInDto | UserSignUpDto;

  protected commonValidators: Record<string, (value: string | undefined, values: AuthFormValues) => string> = {
    email: (value) => (!value ? 'Email cannot be empty.' : ''),
    accessCode: (value) => (!value ? 'Code cannot be empty.' : ''),
    password: (value) => (!value || value.length < 8 ? 'Password must be at least 8 characters.' : ''),
    confirmPassword: (value, values) => (values.password !== value ? 'Passwords do not match.' : ''),
  };

  validate(values: AuthFormValues): Record<string, string> {
    const errors: Record<string, string> = {};
    for (const field in values) {
      const error = this.getFieldError(field as keyof AuthFormValues, values);
      if (error) errors[field] = error;
    }
    return errors;
  }

  getFieldError(field: keyof AuthFormValues, values: AuthFormValues): string {
    const validators = this.getValidators();
    return validators[field] ? validators[field](values[field], values) : '';
  }

  protected abstract getValidators(): Record<string, (value: string | undefined, values: AuthFormValues) => string>;
}

export class LoginStrategy extends BaseAuthStrategy {
  getInitialValues(): AuthFormValues {
    return {
      email: '',
      password: '',
    };
  }

  prepareData(values: AuthFormValues) {
    return {
      email: values.email || '',
      password: values.password || '',
    };
  }

  protected getValidators(): Record<string, (value: string | undefined, values: AuthFormValues) => string> {
    return {
      email: this.commonValidators.email,
      password: this.commonValidators.password,
    };
  }
}

export class SignUpStrategy extends BaseAuthStrategy {
  getInitialValues(): AuthFormValues {
    return {
      accessCode: '',
      password: '',
      confirmPassword: '',
    };
  }

  prepareData(values: AuthFormValues) {
    return {
      accessCode: values.accessCode || '',
      password: values.password || '',
    };
  }

  protected getValidators(): Record<string, (value: string | undefined, values: AuthFormValues) => string> {
    return {
      accessCode: this.commonValidators.accessCode,
      password: this.commonValidators.password,
      confirmPassword: this.commonValidators.confirmPassword,
    };
  }
}

export class RecoveryStrategy extends BaseAuthStrategy {
  getInitialValues(): AuthFormValues {
    return {
      accessCode: '',
      password: '',
      confirmPassword: '',
    };
  }

  prepareData(values: AuthFormValues) {
    return {
      accessCode: values.accessCode || '',
      password: values.password || '',
    };
  }

  protected getValidators(): Record<string, (value: string | undefined, values: AuthFormValues) => string> {
    return {
      accessCode: this.commonValidators.accessCode,
      password: this.commonValidators.password,
      confirmPassword: this.commonValidators.confirmPassword,
    };
  }
}
