import { Typography } from '@mui/material';

const Analytics: React.FC = () => {
  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        Analytics
      </Typography>
    </div>
  );
};

export default Analytics;
