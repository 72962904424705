import {
  ApiKeyResponseDto,
  CreateCustomFieldsDto,
  CreateTokenListItemDto,
  NetworksInfoResponseDto,
  ResponseStatusDto,
  TokenInfoResponseDto,
  TokenListResponseDto,
  UpdateTokenListCustomFieldsDto,
  UpdateTokenListDto,
  UpdateTokenListItemDto,
  UserInfoResponseDto,
  UserSignInDto,
  UserSignUpDto,
} from '../api/api';
import ApiClient from '../api/apiClient';
import { formRequestHeader } from './helpers';

const apiClient = ApiClient();

export const getUserInfo = async (): Promise<UserInfoResponseDto | undefined> => {
  try {
    return await apiClient.UserApi.usersControllerGetUserInfo(formRequestHeader());
  } catch (error) {
    console.error('Error fetching user information:', error);
  }
};

export const getPartnersList = async (listId: string): Promise<TokenListResponseDto | undefined> => {
  try {
    return await apiClient.ListApi.listsControllerGetList(listId, formRequestHeader());
  } catch (error) {
    console.error('Error fetching partner information:', error);
  }
};

export const createToken = async (tokenData: CreateTokenListItemDto): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.TokenApi.tokensControllerCreateToken(tokenData, formRequestHeader());
  } catch (error) {
    console.error('Error creating token:', error);
  }
};

export const updateToken = async (
  tokenData: UpdateTokenListItemDto,
  tokenId: string
): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.TokenApi.tokensControllerUpdateToken(tokenData, tokenId, formRequestHeader());
  } catch (error) {
    console.error('Error updating token:', error);
  }
};

export const generateNewApiKey = async (projectId: string): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.ProjectApi.projectsControllerGenerateApiKey(projectId, formRequestHeader());
  } catch (error) {
    console.error('Error generating new API key:', error);
  }
};

export const getApiKeyByProjectId = async (projectId: string): Promise<ApiKeyResponseDto | undefined> => {
  try {
    return await apiClient.ProjectApi.projectsControllerGetApiKey(projectId, formRequestHeader());
  } catch (error) {
    console.error('Error fetching API key:', error);
  }
};

export const updateTokenList = async (
  updateData: UpdateTokenListDto,
  listId: string
): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.ListApi.listsControllerUpdateList(updateData, listId, formRequestHeader());
  } catch (error) {
    console.error('Error updating token list:', error);
  }
};

export const updateTokenListCustomFields = async (
  updateData: UpdateTokenListCustomFieldsDto,
  listId: string
): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.ListApi.listsControllerUpdateCustomFields(updateData, listId, formRequestHeader());
  } catch (error) {
    console.error('Error updating custom fields in token list:', error);
  }
};

export const createCustomFields = async (
  createData: CreateCustomFieldsDto,
  listId: string
): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.ListApi.listsControllerCreateCustomFields(createData, listId, formRequestHeader());
  } catch (error) {
    console.error('Error creating new custom fields:', error);
  }
};
export const getNetworksList = async (): Promise<NetworksInfoResponseDto | undefined> => {
  try {
    return await apiClient.NetworksApi.networksControllerGetNetworks(formRequestHeader());
  } catch (error) {
    console.error('Error fetching networks:', error);
  }
};

const handleErrorResponse = async (error: unknown, contextMessage: string): Promise<never> => {
  if (error instanceof Response) {
    const errorData = await error.json();
    throw new Error(errorData.message);
  }
  console.error(`${contextMessage}:`, error);
  throw error;
};

export const signUpUser = async (signUpData: UserSignUpDto): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.UserApi.usersControllerSignUp(signUpData, formRequestHeader());
  } catch (error) {
    await handleErrorResponse(error, 'Error signing up user');
  }
};

export const loginUser = async (signInData: UserSignInDto): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.UserApi.usersControllerSignIn(signInData, formRequestHeader());
  } catch (error) {
    await handleErrorResponse(error, 'Error logging in user');
  }
};

export const confirmRecovery = async (recoveryData: UserSignUpDto): Promise<ResponseStatusDto | undefined> => {
  try {
    return await apiClient.UserApi.usersControllerRecover(recoveryData, formRequestHeader());
  } catch (error) {
    await handleErrorResponse(error, 'Error confirming recovery');
  }
};

export const getTokenInfo = async (networkId: string, address: string): Promise<TokenInfoResponseDto | undefined> => {
  try {
    return await apiClient.TokenApi.tokensControllerGetTokenInfo(networkId, address, formRequestHeader());
  } catch (error) {
    console.error('Error fetching token information:', error);
  }
};
