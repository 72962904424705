import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

interface ListInfoProps {
  name?: string;
  note?: string;
  handleSettingsClick: () => void;
}

const ListInfo: React.FC<ListInfoProps> = ({ name, note, handleSettingsClick }) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Typography variant="h5" sx={{ mr: 2 }}>
          {name}
        </Typography>
        <IconButton onClick={handleSettingsClick}>
          <SettingsIcon />
        </IconButton>
      </Box>

      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        {note}
      </Typography>
    </Box>
  );
};

export default ListInfo;
