import React from 'react';

import { TokenListDto } from '../api/api';
import DetailsBlock from './DetailsBlock';
import Modal from './Modal';

interface SettingsModalProps {
  onClose: () => void;
  open: boolean;
  tokenList: TokenListDto | undefined;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ onClose, open, tokenList }) => {
  const label = 'List settings';

  const content = <DetailsBlock tokenList={tokenList} onClose={onClose} />;

  return <Modal open={open} title={label} showCloseButton={true} modalContent={content} onClose={onClose} />;
};

export default SettingsModal;
