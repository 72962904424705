import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ResponseStatusDto, UserSignInDto, UserSignUpDto } from '../api/api';
import { ACCESS_CODE_URL } from '../constants/config';
import { BASE_PATH } from '../constants/routes';
import { confirmRecovery as confirmRecoveryRequest, loginUser, signUpUser } from '../utils/api';

interface UseAuthActionsReturn {
  isLoading: boolean;
  error: string | null;
  signUp: (signUpData: UserSignUpDto) => Promise<ResponseStatusDto | undefined>;
  login: (signInData: UserSignInDto) => Promise<ResponseStatusDto | undefined>;
  logout: () => void;
  confirmRecovery: (recoveryData: UserSignUpDto) => Promise<ResponseStatusDto | undefined>;
  getAccessCode: () => string;
}

export const useAuthActions = (): UseAuthActionsReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const signUp = async (signUpData: UserSignUpDto): Promise<ResponseStatusDto | undefined> => {
    setIsLoading(true);
    setError(null);
    try {
      return await signUpUser(signUpData);
    } catch (err: any) {
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (signInData: UserSignInDto): Promise<ResponseStatusDto | undefined> => {
    setIsLoading(true);
    setError(null);
    try {
      return await loginUser(signInData);
    } catch (err: any) {
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmRecovery = async (recoveryData: UserSignUpDto): Promise<ResponseStatusDto | undefined> => {
    setIsLoading(true);
    setError(null);
    try {
      return await confirmRecoveryRequest(recoveryData);
    } catch (err: any) {
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const getAccessCode = (): string => {
    return ACCESS_CODE_URL || '';
  };

  const logout = async () => {
    localStorage.removeItem('jwt');
    navigate(BASE_PATH.WELCOME);
  };

  return {
    isLoading,
    error,
    signUp,
    login,
    logout,
    confirmRecovery,
    getAccessCode,
  };
};
