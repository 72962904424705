import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

import ListCard from '../components/ListCard';
import { BASE_PATH } from '../constants/routes';
import { useUserInfo } from '../hooks/useUserInfo';

const ProjectPage: React.FC = () => {
  const { userInfo, isLoading } = useUserInfo();

  const projectId = userInfo?.projects[0]?.id;
  const projectName = userInfo?.projects[0]?.name;
  const projectDescription = userInfo?.projects[0]?.note;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ mb: 4 }}>
          {projectName}
        </Typography>

        <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
          {projectDescription}
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="flex-start">
        {userInfo?.projects[0].tokenLists.map((list) => (
          <Grid item xs={12} sm={6} md={3} key={list.id}>
            <NavLink
              to={BASE_PATH.LIST.replace(':projectId', String(projectId)).replace(':listId', String(list.id))}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListCard title={list.name} description={list.note} />
            </NavLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProjectPage;
